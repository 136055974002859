import { ItemDaVenda, Loja } from '@/models'
import FindRegistroDeCustosUseCase from '../custos/FindRegistroDeCustosUseCase'

type UseCaseParams = {
	loja: Loja
	itens: ItemDaVenda[]
	findRegistroDeCustosUseCase?: FindRegistroDeCustosUseCase
}

export default async ({
	loja,
	itens,
	findRegistroDeCustosUseCase = new FindRegistroDeCustosUseCase(),
}: UseCaseParams): Promise<ItemDaVenda[]> => {

	const itensPromise = itens.map(async item => {
		const precoDeCusto = await findRegistroDeCustosUseCase.findPrecoDeCusto({
			lojaId: loja.id,
			sku: item.produto.sku,
		})

		return aplicarPrecoNoItemDaVenda(item, precoDeCusto)
	})
	
	return Promise.all(itensPromise)

}

function aplicarPrecoNoItemDaVenda(itemDaVenda: ItemDaVenda, precoDeCusto: number): ItemDaVenda {
	return {
		...itemDaVenda,
		preco: precoDeCusto,
		precoDeCusto: precoDeCusto,
		desconto: { 
			isPercentual: true,
			valor: 0,
		},
		percentualCashback: 0,
		diasParaEfetivarCashback: 0,
		diasParaExpirarCashback: 0,
		possuiDescontoPorTabela: false,
	}
}
