import { FormEndereco, FormLoja, PontoDeVenda, TipoDePagamento, Usuario } from '@/models';
import { FormSerieFiscal, SerieFiscal } from '@/models/SerieFiscal';
import { Operador } from '@/models/venda/Operador';

export const tipoDePagamentoTemRecorrencia = (tipoDePagamento: TipoDePagamento) =>
	(tipoDePagamento.formaDePagamento === 'Cartão' || tipoDePagamento.formaDePagamento === 'Crediário' || tipoDePagamento.formaDePagamento === 'TEF Crédito' || tipoDePagamento.formaDePagamento === 'Link' || tipoDePagamento.formaDePagamento === 'Outros')
	&& !!tipoDePagamento.parcelasComTaxas

export const verificarSerieRepetida = (seriesFiscais: (FormSerieFiscal | SerieFiscal)[]) =>
	({ serie, ambiente }: SerieFiscal) =>
		!seriesFiscais.some(
			serieFiscal => serieFiscal.serie === serie &&
				serieFiscal.ambiente === ambiente,
		) || `Nº já associado a outra Série Fiscal`

export const verificarSerieFiscalSemPontoDeVendaAssociado = (pdvs: PontoDeVenda[]) =>
	(serieFiscal: SerieFiscal | null) => {
		if (!serieFiscal) return true
		const pdv = pdvs.find(
			pdv => pdv.serieFiscal?.id === serieFiscal?.id,
		)
		return !pdv || `Série associada ao PDV "${pdv.nome}"`
	}

export function criarLoja(): FormLoja {
	return {
		id: '',
		identificador: '',
		cnpj: '',
		nomeFantasia: '',
		endereco: criarEndereco(),
		razaoSocial: '',
		email: '',
		inscricaoEstadual: 'ISENTO',
		inscricaoMunicipal: '',
		telefones: [''],
		urlImagem: '',
		usuarios: [],
		regimeFiscal: 'Simples Nacional',
		pdvs: [],
		cfopPadrao: null,
		configuracaoDaLoja: {
			fazContagemAsCegas: false,
			codigoSegurancaContribuinte: '',
			tabelasDePreco: [],
			idDoContribuinte: 0,
			podeGerarEstoqueNaVenda: true,
			movimentacaoAutomaticaDeEntrada: true,
			forcarMotivoDeMovimentacao: false,
			adicionarAnexosEmMovimentacoes: false,
			lembreteDeCpfCnpj: false,
			devolucaoParcial: false,
			trocoParaCartao: false,
			impressaoDeValeTroca: false,
			diasParaDevolucao: '',
			valorLimiteDeCaixa: 0,
			alteraEstoqueMinimo: false,
			pdvIntegracao: null,
			integracaoControlPayAtiva: false,
			senhaControlPay: '',
			urlControlPay: '',
			senhaTecnicaControlPay: '',
			adquirenteControlPay: '',
			buscaPorCodigoDeBarra: false,
			permiteInventarioComNotasInvalidas: false,
			tipoDeCodigo: 'Sku',
			alterarDataHoraDaVenda: false,
			exigirVerificacaoDeProdutos: false,
			impressaoDeSegundaVia: false,
			trabalharComRomaneio: false,
			exigeMotivoDeCancelamento: false,
			adquirentePixControlPay: '',
			limiteDeDiasParaCancelamentoAposEmissaoDaNota: 0,
			permitirTrocaSemIdentificacao: false,
			permiteAbrirLojaComNotaPendente: false,
			bloquearEdicaoCpfCnpjAposPrimeiraBipagem: false,
			carenciaDoCrediario: 0,
			percentDeJurosDoCrediario: 0,
			multaDoCrediario: 0,
			permitirCriarNotaDeDemonstracaoParaForaDoEstado: false,
			alterarDataHoraDoPedido: false,
			bloquearNovaVenda: false,
			trabalhaComJurosComposto: true,
			permiteConfiguracaoDeNotificacoes: false,
			smtpHost: null,
			smtpPort: null,
			smtpUsuario: null,
			smtpSenha: null,
			permiteVoucherParcial: false,
			limiteDeDiasParaExpirarVoucher: 1,
			criarProdutoImportacaoEntrada: false,
			exibeNotificacaoEstoqueNegativo: false,
			criarProdutoNaTabelaPrecoImportacaoEntrada: false,
			tabelaDePrecoIntegracao: null,
			valorMinimoDeUsoCupom: 0,
		},
		tiposDePagamento: [
			{
				id: '',
				nome: 'Dinheiro',
				formaDePagamento: 'Dinheiro',
				parcelasComTaxas: null,
				diasDeRecorrencia: 0,
				transacoes: ['Venda'],
				crediarioDoFuncionario: false,
				exigeCodAutorizacao: false,
				exigeNsu: false,
				exigeBandeira: false,
				exigeAdquirente: false,
				baixarContas: false,
				fazParteComissao: false,
				utilizaPlugin: false,
				exibirNasDuplicatasDaDanfe: false,
				alterarVencimentoCrediario: false,
				parcelasCrediario: null,
				reservarNumeroDaNota: false,
			},
			{
				id: '',
				nome: 'Vale Presente',
				formaDePagamento: 'Vale Presente',
				parcelasComTaxas: null,
				diasDeRecorrencia: 0,
				transacoes: ['Venda'],
				crediarioDoFuncionario: false,
				exigeCodAutorizacao: false,
				exigeNsu: false,
				exigeBandeira: false,
				exigeAdquirente: false,
				baixarContas: true,
				fazParteComissao: false,
				utilizaPlugin: false,
				exibirNasDuplicatasDaDanfe: false,
				alterarVencimentoCrediario: false,
				parcelasCrediario: null,
				reservarNumeroDaNota: false,
			},
		],
		seriesFiscais: [],
		urlCertificadoNfe: null,
		senhaCertificadoNfe: null,
		campoAdicional: null,
		inativarLoja: false,
		grupoEconomico: {
			id: '',
			descricao: '',
			identificador: '',
		},
		marcas: [],
		clientes: [],
		comandas: [],
		lojas: [],
	}
}

export function usuarioParaOperador(usuario: Usuario): Operador {
	return {
		id: usuario.id,
		ativo: usuario.ativo || true,
		email: usuario.email,
		cpf: usuario.cpf,
		login: usuario.login,
		nome: usuario.nome,
		perfil: {
			id: usuario.perfil.id,
			nome: usuario.perfil.nome,
			gruposEconomicos: [],
			percentualMaximoDesconto: usuario.perfil.percentualMaximoDesconto,
			tempoParaLogoff: usuario.perfil.tempoParaLogoff,
			percentualMaximoDescontoPorItem: usuario.perfil.percentualMaximoDescontoPorItem,
			tempoLimiteDeInatividade: usuario.perfil.tempoLimiteDeInatividade,
		},
		vendedor: false,
	}
}

export function criarEndereco(): FormEndereco {
	return {
		id: null,
		rua: '',
		numero: '',
		cep: '',
		bairro: '',
		complemento: '',
		cidade: '',
		codigoIBGE: '',
		uf: '',
		codigoPais: '',
		nomePais: '',
	}
}