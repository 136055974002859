





























































import { Vue, Component, Ref, PropSync, Watch, Prop } from 'vue-property-decorator'
import { FindClienteUseCase, FindConfiguracaoDeCupomUseCase } from '@/usecases'
import moment from 'moment'
import type { Venda } from '@/models'
import { dateTimeToPtBrFormat } from '@/shareds/date/date-utils'
import { CupomDeDesconto } from '@/models'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import axios, { CancelTokenSource } from 'axios'
import { obterDescontoDaVendaComItens } from '@/shareds/venda-shareds'
import { VendaModule } from '@/store/vuex/venda/VendaStore'

@Component
export default class DialogoDeCupons extends Vue {
	@PropSync('value', { type: Object, default: null }) venda!: Venda
	@Ref() formDeAplicacaoDosCupons!: HTMLFormElement
	@Ref() campoDeCupom!: HTMLInputElement
	@Prop() grupoEconomicoId!: string

	obterDescontoDaVendaComItens = obterDescontoDaVendaComItens

	findConfiguracaoDeCupomUseCase = new FindConfiguracaoDeCupomUseCase()
	findClienteUseCase = new FindClienteUseCase()
	cupomLido: CupomDeDesconto | null = null
	aplicandoCupom = false
	mostraDialogo = false
	codigoDoCupom = ''
	cancelToken: CancelTokenSource | null = null

	mostrar() {
		this.mostraDialogo = true
	}

	@Watch('mostraDialogo', { immediate: true })
	onChangeMostraDialogo(mostraDialogo: boolean) {
		if (!mostraDialogo) return
		setTimeout(() => {
			this.campoDeCupom.focus()
		})
	}

	get cuponsDeDesconto(){
		return this.venda.cuponsDeDesconto
	}

	async aplicarCupom() {
		if (!this.codigoDoCupom) {
			AlertModule.setError('Informe um cupom válido')
			return
		}

		const cupomEstaAplicado = this.venda.cuponsDeDesconto.some(
			({ codigo }) => codigo === this.codigoDoCupom,
		)

		if (cupomEstaAplicado) {
			AlertModule.setError('O cupom já foi aplicado nesta venda.')
			this.aplicandoCupom = false
			return
		}

		try {
			this.aplicandoCupom = true
			this.cancelToken = axios.CancelToken.source()
			this.cupomLido = await this.findConfiguracaoDeCupomUseCase.findByCodigo(this.codigoDoCupom,
				{
					grupoEconomicoId: this.grupoEconomicoId,
				},
				{
					cancelToken: this.cancelToken.token,
				},
			)

			if(!this.cupomLido.permiteUtilizarCupomComDescontoNaVenda && obterDescontoDaVendaComItens(this.venda) > 0 ) {
				AlertModule.setError('Esse cupom não é permitido para venda com desconto')
				this.aplicandoCupom = false
				return
			}

			if(!this.cupomLido.permiteUtilizarMultiplosCuponsNaMesmaVenda) {
				if(this.cuponsDeDesconto.length > 0) {
					AlertModule.setError('Esse cupom parametrizado para uso único na venda, não permitindo multiplos')
					this.aplicandoCupom = false
					return
				}
			}

			const possuiClienteNaVenda = this.venda.cpfDoCliente && this.venda.cpfDoCliente.length > 0
			if(this.cupomLido.numeroDeCupomPorCpf > 0 && possuiClienteNaVenda) {
				const qtdDeCuponsPorCpf = await this.findClienteUseCase.findQtdCuponsPorCpf(
					{
						configuracaoDeCupomId: this.cupomLido.configuracaoDeCupom.id,
						cpfDoCliente: VendaModule.vendaAtual?.cpfDoCliente,
						grupoEconomicoId: this.grupoEconomicoId,
					},
				) + 1

				if(qtdDeCuponsPorCpf > this.cupomLido.numeroDeCupomPorCpf) {
					AlertModule.setError(`Esse cupom está parametrizado para ser aplicado apenas ${this.cupomLido.numeroDeCupomPorCpf}x por CPF`)
					this.aplicandoCupom = false
					return
				}

			}
		} catch (error: any) {
			AlertModule.setError(error)
			this.aplicandoCupom = false
			return
		}

		if (moment().isAfter(moment(this.cupomLido.dataFinalVigencia, 'YYYY-MM-DD HH:mm:ss'))) {
			AlertModule.setError(`Este cupom expirou em ${dateTimeToPtBrFormat(this.cupomLido.dataFinalVigencia || '')}`)
			this.aplicandoCupom = false
			return
		}

		const cupomJaAplicado = this.venda.cuponsDeDesconto.some(
			({ id }) => id === this.cupomLido?.id,
		)
		if (cupomJaAplicado) {
			AlertModule.setError('Cupom já foi aplicado à esta venda')
			this.aplicandoCupom = false
			return
		}

		AlertModule.setSuccess('Cupom aplicado com sucesso')
		this.aplicandoCupom = false

		this.$emit('aplicarCupomNaVenda', this.cupomLido)
		this.fecharDialogo()
	}

	fecharDialogo() {
		setTimeout(() => {
			this.formDeAplicacaoDosCupons.resetValidation()
			this.codigoDoCupom = ''
		})
		this.mostraDialogo = false
	}
}

